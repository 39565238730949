import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './UserSettingsMenu.module.css';
import { FaShoppingBasket, FaPercent, FaTags, FaBell, FaQuestionCircle, FaStar, FaBug, FaExclamationTriangle } from "react-icons/fa";
import { PiFlowerLotusLight } from "react-icons/pi";
import { HiOutlineTicket } from "react-icons/hi2";
import { RiShoppingCartLine } from "react-icons/ri";
import { LiaProductHunt } from "react-icons/lia";
import { PiBellRinging } from "react-icons/pi";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";

import { PiStarLight } from "react-icons/pi";
import { PiBugBeetleLight } from "react-icons/pi";
import { RxExclamationTriangle } from "react-icons/rx";


import { MdPrivacyTip } from "react-icons/md";
import { HiOutlineDocumentText } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiLogout } from "react-icons/ci";
import { HiChevronRight } from "react-icons/hi2";
import { useTheme } from "../ThemeContext/ThemeContext";
import ThemeToggle from "../ThemeToggle/ThemeToggle";


const menuItems = {
    mainSection: [
        { link: "/product-preferences", icon: LiaProductHunt, text: "Product Preferences", includeArrow: true },
        { link: "/deal-preferences", icon: HiOutlineTicket, text: "Deal Preferences", includeArrow: true },
        { link: "/brand-preferences", icon: RiShoppingCartLine, text: "Brand Preferences", includeArrow: true },
        { link: "/notifications", icon: PiBellRinging, text: "Notifications", includeArrow: true },
        { link: "/get-help", icon: HiOutlineQuestionMarkCircle, text: "Get Help", includeArrow: true },
        { link: "/review", icon: PiStarLight, text: "Review On App Store & Google Play", includeArrow: true },
        { link: "/report-bug", icon: PiBugBeetleLight, text: "Report App Bug", includeArrow: true },
        { link: "/report-deal-issue", icon: RxExclamationTriangle, text: "Report Deal Issue", includeArrow: true },
    ],
    bottomLinks: [
        { link: "/terms",  text: "Terms Of Use", includeArrow: false },
        { link: "/privacy", text: "Privacy Policy", includeArrow: false },
        { link: "/delete-account",  text: "Delete Account", includeArrow: false, customClass: 'deleteAccount' },
    ],
};

const UserSettingsMenu = ({ user, handleShowHeader, handleShowSettingsHeader, handleLogout }) => {
    const { isDarkMode, toggleTheme } = useTheme();

    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true);
    }, [handleShowHeader, handleShowSettingsHeader]);

    const renderMenuItem = ({ link, icon: Icon, text, includeArrow, customClass }) => (
        <li key={text} className={`${styles.menuItem} ${customClass ? styles[customClass]:''}`}>
            <Link to={link} className={styles.menuLink}>
                <div className={styles.iconAndName}>
                    {Icon && <Icon className={styles.menuIcon} />}
                    {text}
                </div>
                {includeArrow && <span className={styles.arrow}><HiChevronRight /></span>}
            </Link>
        </li>
    );

    return (
        <nav className={styles.userSettingsMenu}>
            <ul className={styles.menuList}>
                <div className={styles.userSectionHelloWrap}>
                    <div className={styles.userSectionHello}>
                        <h2 className={styles.sectionHeader}>Hello, {user.name}</h2>
                    </div>
                </div>

                <div className={styles.section}>
                    {menuItems.mainSection.map(renderMenuItem)}
                </div>

                <div className={styles.bottomLinks}>
                    {menuItems.bottomLinks.map(renderMenuItem)}
                </div>

                <div className={styles.toggleContainer}>
                    <ThemeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
                </div>

                <div className={styles.section}>
                    <div className={styles.logoutWrap}>
                        <Link className={`${styles.menuLink} ${styles.logout}`}>
                            <div className={`${styles.iconAndName} ${styles.logoutText}`} onClick={handleLogout}>
                                <CiLogout className={`${styles.menuIcon} ${styles.logoutIcon}`} />
                                Logout
                            </div>
                        </Link>
                    </div>
                </div>
            </ul>
        </nav>
    );
};

export default UserSettingsMenu;
