import React, {useState} from "react";
import styles from "./BusinessAdmin.module.css";
import BusinessProfile from "../BusinessProfile/BusinessProfile";
import {Route, Link} from "react-router-dom";
import {MdAdminPanelSettings, MdOutlineRemoveRedEye} from "react-icons/md";
import DealPills from "../DealPills/DealPills";
import {PiMapPinAreaBold} from "react-icons/pi";
import pluugApi from "../../utils/pluug.api";
import StarRating from "../StarRating/StarRating";
import {IoTicketOutline} from "react-icons/io5";
import {TbSettings} from "react-icons/tb";

const BusinessAdmin = ({ business, link }) => {
    return (

        <div className={styles.Business}>
            <Link to={`/business/${business.id}`} className={styles.imageContainer}>

                    <img src={business.avatar_image.small_url} alt=""/>

            </Link>
            <div className={styles.infoColumn}>
                <h2 className={styles.businessLink}>

                    {business.name}
                    <div className={styles.engagement}>
                        <MdOutlineRemoveRedEye/>
                        {business.engagement}
                    </div>
                </h2>
                <div className={styles.ratingDealCount}>
                    <div className={styles.dealCount}>
                        {business.deals.length} Deals
                    </div>
                    <div className={styles.ratingWrap}>
                        <StarRating rating={business.rating} maxRating={5} size={15}/>
                        <h3 className={styles.rating}>{`${business.rating}/5`}</h3>
                    </div>

                </div>

                <div className={styles.BusinessInformation}>
                    <Link to={`/location-settings/${business.id}/deal-manager`} className={`${styles.adminLink} ${styles.dealLink} `}>
                        <IoTicketOutline />
                        Deals
                    </Link>
                    <Link to={`/location-settings/${business.id}/admins`} className={styles.adminLink}>
                        <MdAdminPanelSettings />
                        Admins
                    </Link>
                    <Link to={`/location-settings/${business.id}/settings`} className={styles.adminLink}>
                        <TbSettings />
                        Settings
                    </Link>
                </div>
            </div>

        </div>

    );
};

export default BusinessAdmin;
