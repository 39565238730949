import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {TbArrowBigUp, TbArrowBigDown, TbHeartFilled, TbHeart} from "react-icons/tb";
import styles from './ProductList.module.css';
import useApi from "../../utils/useApi";
import ProductLoader from "./ProductLoader/ProductLoader";
import DealBottomSheet from "../DealBottomSheet/DealBottomSheet";
import {IoArrowUpCircleOutline} from "react-icons/io5";
import {IoMdHeartEmpty} from "react-icons/io";
import {useNavVisibility} from '../NavVisibilityContext/NavVisibilityContext';
import ProductActions from "../ProductActions/ProductActions";

const ProductItem = ({ businessId, product, showTour, setShowTour }) => {
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const { voteProduct, likeProduct, trackProductView } = useApi();
    const [voteStatus, setVoteStatus] = useState(product.vote_status || 0);
    const [voteScore, setVoteScore] = useState(product.vote_score || 0);
    const [heatScore, setHeatScore] = useState(product.heat_score || 0);
    const navigate = useNavigate();



    const handleProductClick = async (e) => {
        e.preventDefault();
        if (product.product_deal && product.product_deal.length > 0) {
            setIsBottomSheetOpen(true);
            try {
                await trackProductView(product.id, product.dispensary_id);
            }
            catch (error) {
                console.error("Failed to track product view:", error);
            }
        } else {
            navigate(`/business/${product.dispensary_id}`);
        }
    };



    const closeBottomSheet = () => {
        setIsBottomSheetOpen(false);
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const ProductContent = () => (
        <>
            <div className={`${styles.productInfoTop} tour-product-info-top`}>
                <div className={`${styles.productImageWrap} tour-product-image`}>
                    <div className={styles.productImage}>
                        <img src={product.formatted_thumbnail_url} alt={product.name}/>
                    </div>
                </div>
                <div className={`${styles.productInfo} tour-product-details`}>
                    <div className={`${styles.productHeader} tour-product-header`}>
                        <h3 className={`${styles.productName} tour-product-name`}>{product.name}</h3>
                    </div>
                    {product.brand_name && (
                        <span className={`${styles.brandName} tour-brand-name`}>{product.brand_name}</span>
                    )}
                    <div className={`${styles.productMetadata} tour-product-metadata`}>
                        {product?.product?.normalized_quantity && (
                            <span className={`${styles.productLabels} ${styles[product?.strain_type?.name?.toLowerCase()]} tour-product-quantity`}>
                                {product?.product?.normalized_quantity}
                            </span>
                        )}
                        {product?.strain_type?.name && (
                            <span className={`${styles.productLabels} ${styles.strainTypeLabel} ${styles[product.strain_type.name.toLowerCase()]} tour-strain-type`}>
                                {capitalizeFirstLetter(product.strain_type.name)}
                            </span>
                        )}
                        {product.product_category && (
                            <span className={`${styles.productLabels} ${styles[product?.strain_type?.name?.toLowerCase()]} tour-product-category`}>
                                {product.product_category}
                            </span>
                        )}
                        {product.dispensary && (
                            <span className={`${styles.dispensaryName} tour-dispensary-name`}>{product.dispensary.name}</span>
                        )}
                        {product.thc_content_label && product.thc_content_label !== "-" && (
                            <span className={`${styles.thcContent} tour-thc-content`}>THC: {product.thc_content_label}</span>
                        )}
                        {product.cbd_content_label && product.cbd_content_label !== "-" && (
                            <span className={`${styles.cbdContent} tour-cbd-content`}>CBD: {product.cbd_content_label}</span>
                        )}
                        {product.distance_mi !== undefined && (
                            <span className={`${styles.dispensaryName} tour-distance`}>
                                {product.distance_mi.toFixed(1)} mi
                            </span>
                        )}
                    </div>
                </div>
                <ProductActions product={product} />
            </div>
            <div className={`${styles.productInfoBottom} tour-product-info-bottom`}>
                <div className={styles.productImagePlaceholderWrap}>
                </div>
                <div className={`${styles.productInfo} tour-product-price-info`}>
                    <div className={`${styles.priceInfo} tour-price-info`}>
                        {product.sale_price ? (
                            <>
                                <div className={`${styles.priceSpans}`}>
                                    <span className={`${styles.salePrice} tour-sale-price`}>${product.sale_price}</span>

                                    <span className={`${styles.originalPrice} tour-original-price`}>${product.price}</span>
                                </div>

                                {(product.product_deal && product.product_deal.length && product.product_deal[0]) && (
                                    <span className={`${styles.discountBadge} tour-discount-badge`}>
                                        {product?.product_deal[0]?.discountLabel && (product.product_deal[0].discountLabel)}
                                        {(!product?.product_deal[0]?.discountLabel && product?.product_deal[0]?.shortDisplayTitle) && (product.product_deal[0].shortDisplayTitle)}
                                    </span>
                                )}
                            </>
                        ) : (
                             <>
                                 <span className={`${styles.price} tour-price`}>${product.price}</span>
                             </>
                         )}
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <>
            <div className={`${styles.productItem} tour-product-item`} onClick={handleProductClick}>
                <ProductContent/>
            </div>

            {(product.product_deal && product.product_deal.length > 0) && (
                <DealBottomSheet
                    businessId={businessId}
                    isOpen={isBottomSheetOpen}
                    onClose={closeBottomSheet}
                    productOrig={product}
                    deal={product.product_deal[0]}
                />
            )}
        </>
    );
};

// Debounce function to limit how often the scroll handler fires
const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

const ProductList = ({ businessId, products, customStyle, loadMore, hasMore, isLoading, page, noDeals, showTour, setShowTour }) => {
    const listRef = useRef();
    const loadingRef = useRef(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const { setIsNavVisible } = useNavVisibility();

    const handleScroll = useCallback(() => {
        console.log('In Handle Scroll Main Nav');
        console.log('Handling Scroll Main Nav');

        const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;
        const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 2500;

        // Detect scroll direction
        if (scrollTop > lastScrollTop) {
            // Scrolling down
            setIsNavVisible(false);
        } else {
            // Scrolling up
            setIsNavVisible(true);
        }

        // Update last scroll position
        setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);

        if (scrolledToBottom && page > 1 && !loadingRef.current) {
            loadingRef.current = true;
            loadMore().finally(() => {
                loadingRef.current = false;
            });
        }
    }, [hasMore, loadMore, lastScrollTop, setIsNavVisible]);

    const debouncedHandleScroll = useCallback(debounce(handleScroll, 200), [handleScroll]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <div className={styles.productList} style={customStyle} ref={listRef}>
            {(noDeals && !isLoading) && (<div className={styles.noDeals}><span>No deals available.</span><br/><span>Here are some other products close by</span></div>)}
            {products.map((product) => (
                <ProductItem businessId={businessId} key={`${product.id}-${product.brand_id}-${product.dispensary_id}`} product={product} showTour={showTour} setShowTour={setShowTour}/>
            ))}
            <div className={isLoading ? styles.loading : styles.loadingHide}>
                {page === 1 && "Loading deals..."}
                {page > 1 && "Loading more deals..."}
            </div>
            <ProductLoader count={3} customClassName={isLoading ? styles.productLoader : styles.productLoaderHide}/>
        </div>
    );
};

export default ProductList;
