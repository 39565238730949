import React, { useState, useCallback, useRef, useEffect, forwardRef, useImperativeHandle, useMemo } from "react";
import styles from "./SearchBar.module.css";
import useApi from "../../utils/useApi";
import { BiMenuAltRight } from "react-icons/bi";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { FiThumbsUp } from "react-icons/fi";
import geolocation from "../../utils/geolocation";
import { PiMapPinAreaBold } from "react-icons/pi";
import { TbMapPinDollar } from "react-icons/tb";


import GeolocationManager from "../../utils/geolocation";

const SearchBar = forwardRef(({ onSearchResults, geoposition, children, onSearchTermChange, onSortByChange, onLocationChange }, ref) => {
    const { isLoading, error, searchGeoProducts } = useApi();
    const [category, setCategory] = useState(2);
    const [sortBy, setSortBy] = useState("near_you");
    const [location, setLocation] = useState("");
    const [term, setTerm] = useState("");
    const [useCurrentLocation, setUseCurrentLocation] = useState(false);
    const [page, setPage] = useState(1);  // Add page state
    const searchTimeoutRef = useRef(null);
    const initialRenderRef = useRef(true);
    const prevGeopositionRef = useRef();

    const sortByOptions = useMemo(() => ({
        "Near You": {
            key: "near_you",
            icon: BiMenuAltRight
        },
        "Steals": {
            key: "best_deal",
            icon: TbMapPinDollar
        },
        "Favorites": {
            key: "favorites",
            icon: FaRegHeart,
            activeIcon: FaHeart
        },
    }), []);

    const performSearch = useCallback(async (useCurrentLocationOverride = false, currentSortBy = sortBy) => {
        console.log("Performing search");
        console.log("Current location:", location);
        console.log("Current geoposition:", geoposition);
        console.log("Sort by:", currentSortBy);
        console.log("Page:", page);  // Log the current page
        try {
            const geo = useCurrentLocationOverride || useCurrentLocation ? geoposition : geolocation.getStoredLocation();
            //const results = await searchGeoProducts(term, geo, category, currentSortBy, page);

            //onSearchResults(results);

            localStorage.setItem('lastSearchedTerm', term);
            if (!useCurrentLocation && !useCurrentLocationOverride) {
                localStorage.setItem('lastSearchedLocation', location);
            }
        }
        catch (error) {
            console.error("Error searching:", error);
        }
    }, [term, location, onSearchResults, useCurrentLocation, geoposition]);  // Add page to dependencies

    useImperativeHandle(ref, () => ({
        performSearch: () => performSearch(false, sortBy)
    }));

    useEffect(() => {
        if (!initialRenderRef.current &&
            (prevGeopositionRef.current?.city !== geoposition?.city ||
             prevGeopositionRef.current?.state_abbr !== geoposition?.state_abbr)) {
            console.log("Geoposition changed, performing new search");
            setLocation(`${geoposition.city}, ${geoposition.state_abbr}`);
            setPage(1);  // Reset page to 1 when geoposition changes
            performSearch(true, sortBy);
        }
        prevGeopositionRef.current = geoposition;
    }, [geoposition, sortBy, performSearch]);

    const debouncedSearch = useCallback(() => {
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }
        searchTimeoutRef.current = setTimeout(() => {
            setPage(1);  // Reset page to 1 when performing a new search
            performSearch(false, sortBy);
        }, 1000);
    }, [performSearch, sortBy]);

    const handleLocationChange = useCallback((ev) => {
        setLocation(ev.target.value);
        setUseCurrentLocation(false);
        debouncedSearch();
    }, [debouncedSearch]);

    const handleTermChange = useCallback((ev) => {
        setTerm(ev.target.value);
        onSearchTermChange(ev.target.value);
        debouncedSearch();
    }, [debouncedSearch, onSearchTermChange]);

    const handleSortByChange = useCallback((newSortBy) => {
        const newSortByKey = sortByOptions[newSortBy].key;
        console.log(newSortBy);
        console.log(newSortByKey);
        setSortBy(newSortByKey);
        onSortByChange(newSortByKey);
        setPage(1);  // Reset page to 1 when changing sort option
        performSearch(false, newSortByKey);
    }, [sortByOptions, performSearch, onSortByChange]);

    const handleUseCurrentLocation = useCallback(() => {
        onLocationChange(true);
        setUseCurrentLocation(true);
        setLocation(`${geoposition.city}, ${geoposition.state_abbr}`);
        setPage(1);  // Reset page to 1 when using current location
        performSearch(true, sortBy);
    }, [geoposition, performSearch, sortBy]);

    const renderSortByOptions = useMemo(() => {
        return Object.keys(sortByOptions).map((sortByOption) => {
            const option = sortByOptions[sortByOption];
            const isActive = sortBy === option.key;
            const Icon = isActive && option.activeIcon ? option.activeIcon : option.icon;

            return (
                <button
                    className={isActive ? styles.active : ''}
                    onClick={() => handleSortByChange(sortByOption)}
                    key={option.key}
                    disabled={isActive}
                >
                    <Icon/>
                    {sortByOption}
                </button>
            );
        });
    }, [sortByOptions, sortBy, handleSortByChange]);

    return (
        <div className={styles.SearchBar}>
            <div className={styles.SearchBarSortOptions}>
                <ul>{renderSortByOptions}</ul>
            </div>
            <div className={styles.MapContainer}>
                {children}
                <button
                    className={styles.UseCurrentLocationButton}
                    onClick={handleUseCurrentLocation}
                >
                    <PiMapPinAreaBold size={24}/>
                </button>
            </div>

            {error && <p>Error: {error}</p>}
        </div>
    );
});

export default React.memo(SearchBar);
