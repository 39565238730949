import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TbHeart, TbHeartFilled } from "react-icons/tb";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import styles from './MenuView.module.css';
import useApi from "../../utils/useApi";
import ProductLoader from "../ProductList/ProductLoader/ProductLoader";
import ProductLoaderGrid from "../ProductLoaderCard/ProductLoaderGrid";
import ProductLoaderCard from "../ProductLoaderCard/ProductLoaderCard";

const ProductCard = ({ product }) => {
    const { likeProduct } = useApi();

    const handleLike = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            const response = await likeProduct(product.id);
            product.liked = response.like;
        } catch (error) {
            console.error("Failed to toggle the like status:", error);
        }
    };

    return (
        <a target="_blank" href={product.link} className={styles.productCardWrap}>
            <div className={styles.productCard}>
                <div
                    className={styles.productImage}
                    style={{ backgroundImage: `url(${product.formatted_thumbnail_url})` }}
                >
                    {product.deal_id && (
                        <div className={styles.discountLabel}>Deal</div>
                    )}
                    <div className={styles.productActions}>
                        <button
                            onClick={handleLike}
                            className={styles.likeButton}
                        >
                            {product.liked ? (
                                <TbHeartFilled size={15} className={styles.liked} />
                            ) : (
                                 <TbHeart size={15} />
                             )}
                        </button>
                        {product.deal_id && (
                            <a
                                href={`/deals/${product.deal_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.dealLink}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <IoArrowUpCircleOutline size={18} />
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.productInfo}>
                <h3 className={styles.productName}>{product.name}</h3>
                <p className={styles.priceStrikethrough}>
                    {product.sale_price ? `$${product.price}` : ''}
                </p>
                <p className={styles.productPrice}>
                    {product.sale_price ? `$${product.sale_price}` : `$${product.price}`}
                </p>
            </div>
        </a>
    );
};

const MenuView = ({ categories, businessId }) => {
    const { getBusinessMenuCategory } = useApi();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const loadingRef = useRef(false);
    const timerRef = useRef(null);
    const initialLoadDone = useRef(false);

    useEffect(() => {
        if (categories && Object.keys(categories).length > 0) {
            const categoryList = Object.keys(categories).map(key => ({
                id: categories[key].id,
                name: categories[key].name,
                count: categories[key].count
            }));
            setSelectedCategory(categoryList[0]);
        }
    }, [categories]);

    const loadProducts = useCallback(async (reset = false) => {
        if (!selectedCategory || loadingRef.current || (!hasMore && !reset)) return;

        loadingRef.current = true;
        setLoading(true);

        try {
            const currentPage = reset ? 1 : page;
            const response = await getBusinessMenuCategory(businessId, selectedCategory.id, currentPage);

            setProducts(prevProducts => reset ? response.data : [...prevProducts, ...response.data]);
            setPage(prevPage => reset ? 2 : prevPage + 1);
            setHasMore(response.current_page < response.last_page);
        } catch (error) {
            console.error("Failed to load products:", error);
        } finally {
            setLoading(false);
            loadingRef.current = false;
            initialLoadDone.current = true;
        }
    }, [businessId, getBusinessMenuCategory, page, selectedCategory, hasMore]);

    useEffect(() => {
        if (selectedCategory && !initialLoadDone.current) {
            loadProducts(true);
        }
    }, [selectedCategory, loadProducts]);

    const handleScroll = useCallback(() => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            const scrollPosition = window.innerHeight + window.scrollY;
            const threshold = document.documentElement.offsetHeight - 1500;

            if (scrollPosition >= threshold && !loadingRef.current && hasMore && initialLoadDone.current) {
                loadProducts();
            }
        }, 200);
    }, [loadProducts, hasMore]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, [handleScroll]);

    const handleCategoryClick = (category) => {
        if (category.id !== selectedCategory?.id) {
            setSelectedCategory(category);
            setPage(1);
            setProducts([]);
            setHasMore(true);
            initialLoadDone.current = false;
        }
    };

    return (
        <div className={styles.menuContainer}>
            <div className={styles.categoryNav}>
                {Object.keys(categories).map(key => (
                    <button
                        key={categories[key].id}
                        onClick={() => handleCategoryClick(categories[key])}
                        className={`${styles.categoryButton} ${selectedCategory?.id === categories[key].id ? styles.activeCategory : ''}`}
                    >
                        {categories[key].name} ({categories[key].count})
                    </button>
                ))}
            </div>
            <div className={styles.productsList}>

                {products.map(product => (
                    <ProductCard key={product.id} product={product} />
                ))}

                {loading && (<ProductLoaderCard count={30} customClassName={styles.productCardWrap} priceBelow={true} />)}

            </div>



        </div>
    );
};

export default MenuView;
