import React, { createContext, useState, useEffect, useContext } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme === 'dark') {
            return true;
        } else if (savedTheme === 'light') {
            return false;
        } else {
            return window.matchMedia('(prefers-color-scheme: dark)').matches;
        }
    });

    useEffect(() => {
        const root = document.documentElement;
        if (isDarkMode) {
            root.style.setProperty('--settings-icon', 'var(--dark-settings-icon)');
            root.style.setProperty('--background-accent-color', 'var(--dark-black-background)');
            root.style.setProperty('--background-color', 'var(--dark-background)');
            root.style.setProperty('--text-color', 'var(--dark-text)');
            root.style.setProperty('--text-color-secondary', 'var(--dark-text-secondary)');
            root.style.setProperty('--primary-color', 'var(--dark-primary)');
            root.style.setProperty('--secondary-color', 'var(--dark-secondary)');
            root.style.setProperty('--thirdary-color', 'var(--dark-thirdary)');
            root.style.setProperty('--accent-color', 'var(--dark-accent)');
            root.style.setProperty('--vote-green', 'var(--dark-vote-green)');
            root.style.setProperty('--icon-transparent', 'var(--dark-icon-transparent)');
            root.style.setProperty('--discount-badge', 'var(--light-discount-badge)');
            root.style.setProperty('--price-color', 'var(--light-accent)');
            root.style.setProperty('--product-border', 'var(--product-border-dark)');


        } else {
            root.style.setProperty('--settings-icon', 'var(--light-settings-icon)');
            root.style.setProperty('--background-accent-color', 'var(--light-accent-background)');
            root.style.setProperty('--background-color', 'var(--light-background)');
            root.style.setProperty('--text-color', 'var(--light-text)');
            root.style.setProperty('--text-color-secondary', 'var(--light-text-secondary)');
            root.style.setProperty('--primary-color', 'var(--light-primary)');
            root.style.setProperty('--secondary-color', 'var(--light-accent-background)');
            root.style.setProperty('--thirdary-color', 'var(--light-thirdary)');
            root.style.setProperty('--accent-color', 'var(--light-accent)');
            root.style.setProperty('--vote-green', 'var(--light-vote-green)');
            root.style.setProperty('--icon-transparent', 'var(--light-icon-transparent)');
            root.style.setProperty('--discount-badge', 'var(--light-discount-badge)');
            root.style.setProperty('--price-color', 'var(--branding-purple)');
            root.style.setProperty('--product-border', 'var(--product-border-light)');



        }
        localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    const toggleTheme = () => {
        setIsDarkMode(prevMode => !prevMode);
    };

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);
