import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProductLoader.module.css';

const ProductLoaderItem = () => (
    <div className={styles.productItem}>
        <div className={`${styles.productImage} ${styles.animatePulse}`}></div>
        <div className={styles.productInfo}>
            <div className={`${styles.productName} ${styles.animatePulse}`}></div>
            <div className={`${styles.productCategory} ${styles.animatePulse}`}></div>
            <div className={styles.productMetadata}>
                <div className={`${styles.metadataItem} ${styles.animatePulse}`}></div>
                <div className={`${styles.metadataItem} ${styles.animatePulse}`}></div>
            </div>
            <div className={styles.productMetadata}>
                <div className={`${styles.metadataItem} ${styles.animatePulse}`}></div>
                <div className={`${styles.metadataItem} ${styles.animatePulse}`}></div>
            </div>
            <div className={`${styles.priceInfo} ${styles.animatePulse}`}></div>
        </div>
    </div>
);

const ProductLoader = ({ count = 15, customClassName }) => (
    <div className={customClassName ?? styles.productList}>
        {[...Array(count)].map((_, index) => (
            <ProductLoaderItem key={index} />
        ))}
    </div>
);

ProductLoader.propTypes = {
    count: PropTypes.number
};

export default ProductLoader;
