import React, {useState, Fragment, useRef, useEffect, useCallback} from 'react';
import {Listbox, ListboxOption, Transition} from '@headlessui/react';
import {TrendingUp, Search, Filter, X} from 'lucide-react';
import styles from './NavbarFilter.module.css';
import MenuIcon from "../Icons/MenuIcon/MenuIcon";
// Import SVG icons (unchanged)
import FlowerMapMarker from "../../assets/map-markers/flower-marker.svg";
import AccessoryMapMarker from "../../assets/map-markers/accessory-marker.svg";
import ConcentrateMapMarker from "../../assets/map-markers/concentrate-marker.svg";
import EdibleMapMarker from "../../assets/map-markers/edible-marker.svg";
import PrerollMapMarker from "../../assets/map-markers/preroll-marker.svg";
import TopicalMapMarker from "../../assets/map-markers/topical-marker.svg";
import VapeMapMarker from "../../assets/map-markers/vape-marker.svg";

import {ReactComponent as PrerollIcon} from "../../assets/preroll.svg";
import {ReactComponent as FlowerIcon} from "../../assets/flower.svg";
import {ReactComponent as CartridgeIcon} from "../../assets/cartridge.svg";
import {ReactComponent as TopicalIcon} from "../../assets/topical.svg";
import {ReactComponent as EdibleIcon} from "../../assets/edible.svg";
import {ReactComponent as AccessoryIcon} from "../../assets/accessory.svg";
import {ReactComponent as WaxIcon} from "../../assets/wax.svg";


// Custom debounce hook
const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

const filters = [
    { name: 'Trending', Icon: TrendingUp, param: 'trending', id: 'trending', type: 'icon' },
    { name: 'Flower', Icon: FlowerIcon, param: 'flower', id: 2, mapMarker: FlowerMapMarker  },
    { name: 'Pre-Rolls', Icon: PrerollIcon, param: 'PreRoll', id: 402, mapMarker: PrerollMapMarker },
    { name: 'Concentrates', Icon: WaxIcon, param: 'concentrate', id: 361, mapMarker: ConcentrateMapMarker },
    { name: 'Vapes', Icon: CartridgeIcon, param: 'cartridge', id: 193, mapMarker: VapeMapMarker },
    { name: 'Edibles', Icon: EdibleIcon, param: 'edible', id: 371, mapMarker: EdibleMapMarker },
    { name: 'Topicals', Icon: TopicalIcon, param: 'topical', id: 424, mapMarker: TopicalMapMarker },
    { name: 'Accessories', Icon: AccessoryIcon, param: 'accessory', id: 350, mapMarker: AccessoryMapMarker },
];

const priceRanges = [
    { from: 0, to: 20, label: 'Under $20' },
    { from: 20, to: 40, label: '$20 - $40' },
    { from: 40, to: 60, label: '$40 - $60' },
    { from: 60, to: 80, label: '$60 - $80' },
    { from: 80, to: Infinity, label: '$80 & above' },
];

const weights = [
    { value: 0.5, label: '1/2g' },
    { value: 1, label: '1g' },
    { value: 3.5, label: '3.5g' },
    { value: 7, label: '7g' },
    { value: 14, label: '14g' },
    { value: 28, label: '28g' },
];

const strainTypes = [
    { id: 1, label: 'sativa', customClass: 'branding-red' },
    { id: 2, label: 'indica', customClass: 'branding-purple' },
    { id: 3, label: 'hybrid', customClass: 'branding-green' }
];

const NavbarFilter = ({ businessId, onTermChange, onCategoryChange, onPriceChange, onWeightChange, onCBDChange, onTHCChange, onStrainTypeChange, onDistanceChange, filterCounts, pinned }) => {
    const isFilterDisabled = (count) => count == 0;
    const [term, setTerm] = useState('');
    const debouncedTerm = useDebounce(term, 600);
    const [selectedFilter, setSelectedFilter] = useState(filters[1]);
    const [cbdMinMax, setCbdMinMax] = useState({ min: 0, max: 75 });
    const [thcMinMax, setThcMinMax] = useState({ min: 9.96, max: 95 });
    const [localCBDRange, setLocalCBDRange] = useState([0, 75]);
    const [localTHCRange, setLocalTHCRange] = useState([9.96, 95]);
    const [selectedStrainType, setSelectedStrainType] = useState(null);
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [isFullFilterVisible, setIsFullFilterVisible] = useState(false);
    const [distance, setDistance] = useState((!businessId ? 3: null)); // Default to 50 miles
    const [activeFilters, setActiveFilters] = useState({
                                                           price: null,
                                                           weight: null,
                                                           strainType: null,
                                                           distance: distance,
                                                       });

    const searchInputRef = useRef(null);

    useEffect(() => {
        if (filterCounts && filterCounts.cbd_range) {
            setCbdMinMax({
                             min: 0,
                             max: 100
                         });
            setLocalCBDRange([
                                 0,
                                 100
                             ]);
        }
        if (filterCounts && filterCounts.thc_range) {
            setThcMinMax({
                             min: 0,
                             max: 100
                         });
            setLocalTHCRange([
                                 0,
                                 100
                             ]);
        }
    }, [filterCounts]);


    const handleStrainTypeClick = (strainType) => {
        setSelectedStrainType(strainType);
        setActiveFilters(prev => ({ ...prev, strainType: { id: strainType.id, label: strainType.label } }));
        onStrainTypeChange(strainType.id);
        toggleFullFilter();
    };

    const removeStrainTypeFilter = () => {
        setSelectedStrainType(null);
        setActiveFilters(prev => ({ ...prev, strainType: null }));
        onStrainTypeChange(null);
    };

    const resetFilters = () => {

    }

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setActiveFilters(prev => ({ ...prev, weight: null, strainType: null }));

        console.log(filter);
        onCategoryChange(filter);
        onStrainTypeChange(null);
        onWeightChange(null);
        removeStrainTypeFilter();
        resetStrengthFilters();

    };

    const handlePriceClick = (from, to, label) => {
        setActiveFilters(prev => ({ ...prev, price: { from, to, label } }));
        onPriceChange(from, to);
        toggleFullFilter();
    };

    const handleWeightClick = (weight, label) => {
        setActiveFilters(prev => ({ ...prev, weight: { value: weight, label } }));
        onWeightChange(weight);
        toggleFullFilter();
    };

    const removeFilter = (filterType) => {
        setActiveFilters(prev => ({ ...prev, [filterType]: null }));
        if (filterType === 'price') {
            onPriceChange(null, null);
        } else if (filterType === 'weight') {
            onWeightChange(null);
        } else if (filterType === 'distance') {
            setDistance(3);
            onDistanceChange(3);
        }
    };

    const toggleSearch = () => {
        setIsSearchVisible(!isSearchVisible);
        if (!isSearchVisible) {
            setTimeout(() => searchInputRef.current?.focus(), 300);
        }
    };

    const handleSearchChange = (ev) => {
        setTerm(ev.target.value);

        setTimeout(function (){
            window.scrollTo({ top: 0, behavior: 'smooth' });

        }, 200);
    };

    useEffect(() => {
        onTermChange(debouncedTerm);
    }, [debouncedTerm, onTermChange]);

    const handleSearchSubmit = (ev) => {
        ev.preventDefault();
        onTermChange(term); // Immediately trigger search on form submit
    };

    const handleSearchBlur = () => {
        setIsSearchVisible(false);
    };

    const toggleFullFilter = () => {
        setIsFullFilterVisible(!isFullFilterVisible);
    };

    const handleLocalCBDChange = (newRange) => {
        const sortedRange = [
            Math.max(cbdMinMax.min, Math.min(...newRange)),
            Math.min(cbdMinMax.max, Math.max(...newRange))
        ];
        setLocalCBDRange(sortedRange);
    };

    const handleLocalTHCChange = (newRange) => {
        const sortedRange = [
            Math.max(thcMinMax.min, Math.min(...newRange)),
            Math.min(thcMinMax.max, Math.max(...newRange))
        ];
        setLocalTHCRange(sortedRange);
    };

    const resetStrengthFilters =() => {
        const sortedRange = [
            0,
            100
        ];

        setLocalTHCRange(sortedRange);
        setLocalTHCRange(sortedRange);

        onCBDChange(null, null);
        onTHCChange(null, null);
    }

    const handleDistanceChange = (newDistance) => {
        setDistance(newDistance);
        setActiveFilters(prev => ({ ...prev, distance: newDistance }));
    };

    const applyAndCloseFilters = () => {
        if(localCBDRange[0] !== 0 && localCBDRange[1] !== 100){
            onCBDChange(localCBDRange[0], localCBDRange[1]);

        }
        if(localTHCRange[0] !== 0 && localTHCRange[1] !== 100){
            onTHCChange(localTHCRange[0], localTHCRange[1]);
        }
        onDistanceChange(distance);
        toggleFullFilter();
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const showWeightFilters = selectedFilter.id === 2 || selectedFilter.id === 193 || selectedFilter.id === 402; // Flower or Vapes
    const showStrengthFilters = selectedFilter.id === 2 || selectedFilter.id === 193 || selectedFilter.id === 361 || selectedFilter.id === 402;

    return (
        <div className={`${styles.navbarFilterWrapper}`}>
            <nav className={pinned ? `${styles.navbar} ${styles.navbarPinned}`:`${styles.navbar} navbar-filter`}>
                <Listbox value={selectedFilter} onChange={handleFilterChange}>
                    {({ open }) => (
                        <>
                            <div className={styles.listboxContainer}>
                                <Listbox.Button className={styles.listboxButton}>
                                    <MenuIcon className={styles.menuIcon}/>
                                </Listbox.Button>
                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className={styles.listboxOptions}>
                                        {filters.map((filter) => (
                                            <ListboxOption
                                                key={filter.name}
                                                className={({ active }) =>
                                                    `${styles.listboxOption} ${active ? styles.activeOption : ''}`
                                                }
                                                value={filter}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <filter.Icon className={styles.icon} aria-hidden="true"/>
                                                        <span className={`${styles.optionText} ${selected ? styles.selectedOption : ''}`}>
                                                            {filter.name}
                                                        </span>
                                                    </>
                                                )}
                                            </ListboxOption>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                            <Listbox.Button as="div" className={styles.selectedFilter}>
                                <selectedFilter.Icon className={styles.icon}/>
                                <span>{selectedFilter.name}</span>
                            </Listbox.Button>
                        </>
                    )}
                </Listbox>

                <form onSubmit={handleSearchSubmit} className={`${styles.searchContainer} ${isSearchVisible ? styles.visible : ''}`}>
                    <input
                        ref={searchInputRef}
                        type="text"
                        placeholder="Search..."
                        className={styles.searchInput}
                        onBlur={handleSearchBlur}
                        onChange={handleSearchChange}
                        value={term}
                    />
                    <button type="submit" style={{ display: 'none' }}></button>
                </form>

                <button className={`${styles.iconButtonSearch} ${isSearchVisible ? styles.iconButtonSearchVisible : ''}`} onClick={toggleSearch}>
                    <Search className={styles.icon}/>
                </button>

                <button className={styles.iconButton} onClick={toggleFullFilter}>
                    <Filter className={styles.icon}/>
                </button>
            </nav>

            <div className={styles.activeFiltersContainer}>
                {activeFilters.strainType && (
                    <div className={`${styles.activeFilter} ${styles[activeFilters.strainType.label]} ${pinned ? styles[activeFilters.strainType.label+ 'Pinned']:''}`}>
                        <span onClick={toggleFullFilter}>{capitalizeFirstLetter(activeFilters.strainType.label)}</span>
                        <button onClick={removeStrainTypeFilter}><X size={16}/></button>
                    </div>
                )}
                {activeFilters.price && (
                    <div className={`${styles.activeFilter} ${pinned ? styles['activeFilterPinned']:''}`}>
                        <span onClick={toggleFullFilter}>{activeFilters.price.label}</span>
                        <button onClick={() => removeFilter('price')}><X size={16}/></button>
                    </div>
                )}
                {activeFilters.weight && (
                    <div className={`${styles.activeFilter} ${pinned ? styles['activeFilterPinned']:''}`}>
                        <span onClick={toggleFullFilter}>{activeFilters.weight.label}</span>
                        <button onClick={() => removeFilter('weight')}><X size={16}/></button>
                    </div>
                )}
                {activeFilters.distance && (
                    <div className={`${styles.activeFilter} ${pinned ? styles['activeFilterPinned']:''}`}>
                        <span onClick={toggleFullFilter}>Within {activeFilters.distance} miles</span>
                        <button onClick={() => removeFilter('distance')}><X size={16}/></button>
                    </div>
                )}
            </div>

            {isFullFilterVisible && (
                <div className={styles.fullFilterContainer}>
                    <div className={styles.filterHeader}>
                        <h2>Filters</h2>
                        <button className={styles.closeButton} onClick={toggleFullFilter}>
                            <X className={styles.closeIcon}/>
                        </button>
                    </div>
                    {distance > 0 && (<div className={styles.filterSection}>
                        <h3>Distance</h3>
                        <span className={styles.rangeValues}>
                            Within {distance} miles
                        </span>
                        <div className={styles.rangeSlider}>
                            <div
                                className={styles.rangeProgress}
                                style={{
                                    width: `${(distance / 25) * 100}%`
                                }}
                            ></div>
                            <input
                                type="range"
                                min={0}
                                max={25}
                                step={1}
                                value={distance}
                                onChange={(e) => handleDistanceChange(parseInt(e.target.value))}
                                className={styles.rangeInput}
                            />
                        </div>
                    </div>)}

                    <div className={styles.filterSection}>
                        <h3>Price</h3>
                        <div className={styles.filterOptions}>
                            {priceRanges.map((range, index) => {
                                const count = filterCounts.prices && filterCounts.prices[index] || 0;
                                return (
                                    <button
                                        key={range.label}
                                        onClick={() => !isFilterDisabled(count) && handlePriceClick(range.from, range.to, range.label)}
                                        data-from={range.from}
                                        data-to={range.to}
                                        className={`${activeFilters.price && activeFilters.price.from === range.from ? styles.activeFilterButton : ''} ${isFilterDisabled(count) ? styles.disabledFilterButton : ''}`}
                                        disabled={isFilterDisabled(count) || (activeFilters.price && activeFilters.price.from === range.from)}
                                    >
                                        {range.label} ({count})
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                    {showWeightFilters && (
                        <div className={styles.filterSection}>
                            <h3>Weight</h3>
                            <div className={styles.filterOptions}>
                                {weights.map((weight, index) => {
                                    const count = filterCounts.weights && filterCounts.weights[index] || 0;
                                    return (
                                        <button
                                            key={weight.label}
                                            onClick={() => !isFilterDisabled(count) && handleWeightClick(weight.value, weight.label)}
                                            data-weight={weight.value}
                                            className={`${activeFilters.weight && activeFilters.weight.value === weight.value ? styles.activeFilterButton : ''} ${isFilterDisabled(count) ? styles.disabledFilterButton : ''}`}
                                            disabled={isFilterDisabled(count) || (activeFilters.weight && activeFilters.weight.value === weight.value)}
                                        >
                                            {weight.label} ({count})
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    <div className={styles.filterSection}>
                        <h3>Strain Type</h3>
                        <div className={styles.filterOptions}>
                            {strainTypes.map((strainType) => {
                                const count = filterCounts.strainTypes && filterCounts.strainTypes[strainType.id] || 0;
                                return (
                                    <button
                                        key={strainType.id}
                                        onClick={() => !isFilterDisabled(count) && handleStrainTypeClick(strainType)}
                                        className={`${selectedStrainType && selectedStrainType.id === strainType.id ? styles.activeFilterButton : ''} ${isFilterDisabled(count) ? styles.disabledFilterButton : ''} ${strainType.customClass}`}
                                        disabled={isFilterDisabled(count) || (selectedStrainType && selectedStrainType.id === strainType.id)}
                                    >
                                        {capitalizeFirstLetter(strainType.label)} ({count})
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                    {showStrengthFilters && (
                        <>
                            <div className={styles.filterSection}>
                                <h3>THC</h3>
                                <span className={styles.rangeValues}>
                            {localTHCRange[0].toFixed(2)}% - {localTHCRange[1].toFixed(2)}%
                        </span>
                                <div className={styles.rangeSlider}>
                                    <div
                                        className={styles.rangeProgress}
                                        style={{
                                            left: `${((localTHCRange[0] - thcMinMax.min) / (thcMinMax.max - thcMinMax.min)) * 100}%`,
                                            width: `${((localTHCRange[1] - localTHCRange[0]) / (thcMinMax.max - thcMinMax.min)) * 100}%`
                                        }}
                                    ></div>
                                    <input
                                        type="range"
                                        min={thcMinMax.min}
                                        max={thcMinMax.max}
                                        step="0.01"
                                        value={localTHCRange[0]}
                                        onChange={(e) => handleLocalTHCChange([parseFloat(e.target.value), localTHCRange[1]])}
                                        className={styles.rangeInput}
                                    />
                                    <input
                                        type="range"
                                        min={thcMinMax.min}
                                        max={thcMinMax.max}
                                        step="0.01"
                                        value={localTHCRange[1]}
                                        onChange={(e) => handleLocalTHCChange([localTHCRange[0], parseFloat(e.target.value)])}
                                        className={styles.rangeInput}
                                    />
                                </div>
                            </div>

                            <div className={styles.filterSection}>
                                <h3>CBD</h3>
                                <span className={styles.rangeValues}>
                            {localCBDRange[0].toFixed(2)}% - {localCBDRange[1].toFixed(2)}%
                        </span>
                                <div className={styles.rangeSlider}>
                                    <div
                                        className={styles.rangeProgress}
                                        style={{
                                            left: `${((localCBDRange[0] - cbdMinMax.min) / (cbdMinMax.max - cbdMinMax.min)) * 100}%`,
                                            width: `${((localCBDRange[1] - localCBDRange[0]) / (cbdMinMax.max - cbdMinMax.min)) * 100}%`
                                        }}
                                    ></div>
                                    <input
                                        type="range"
                                        min={cbdMinMax.min}
                                        max={cbdMinMax.max}
                                        step="0.01"
                                        value={localCBDRange[0]}
                                        onChange={(e) => handleLocalCBDChange([parseFloat(e.target.value), localCBDRange[1]])}
                                        className={styles.rangeInput}
                                    />
                                    <input
                                        type="range"
                                        min={cbdMinMax.min}
                                        max={cbdMinMax.max}
                                        step="0.01"
                                        value={localCBDRange[1]}
                                        onChange={(e) => handleLocalCBDChange([localCBDRange[0], parseFloat(e.target.value)])}
                                        className={styles.rangeInput}
                                    />
                                </div>
                            </div>
                        </>
                    )}


                    <div className={styles.applyButtonContainer}>
                        <button className={styles.applyButton} onClick={applyAndCloseFilters}>
                            Apply
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NavbarFilter;
