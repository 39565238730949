import React from 'react';
import {FaMoon, FaSun} from 'react-icons/fa';
import styles from './ThemeToggle.module.css';

const ThemeToggle = ({ isDarkMode, toggleTheme }) => {
    return (
        <div className={styles.themeToggleContainer}>
            <label className={styles.switch}>
                <input
                    type="checkbox"
                    checked={isDarkMode}
                    onChange={toggleTheme}
                />
                <span className={styles.slider}>
                      <FaSun className={isDarkMode ? styles.darkIcon:styles.icon}/>
                      <FaMoon className={!isDarkMode ? styles.darkIcon:styles.icon}/>
                    </span>
            </label>
            <div className={styles.labels}>
                <span className={`${styles.label} ${!isDarkMode && styles.active}`}>Light</span>

                <span className={`${styles.label} ${isDarkMode && styles.active}`}>Dark</span>
            </div>

        </div>
    );
};

export default ThemeToggle;
