import React from 'react';
import styles from './MapLoader.module.css';

const MapLoader = () => (
    <div className={styles.mapLoader}>
        <div className={styles.mapBackground}>
            <div className={`${styles.road} ${styles.horizontal}`}></div>
            <div className={`${styles.road} ${styles.vertical}`}></div>
            <div className={`${styles.pin} ${styles.animatePulse}`} style={{ top: '20%', left: '30%' }}></div>
            <div className={`${styles.pin} ${styles.animatePulse}`} style={{ top: '40%', left: '50%' }}></div>
            <div className={`${styles.pin} ${styles.animatePulse}`} style={{ top: '60%', left: '70%' }}></div>
            <div className={`${styles.label} ${styles.animatePulse}`} style={{ top: '10%', left: '10%' }}></div>
            <div className={`${styles.label} ${styles.animatePulse}`} style={{ top: '70%', left: '20%' }}></div>
            <div className={`${styles.label} ${styles.animatePulse}`} style={{ top: '30%', left: '80%' }}></div>
        </div>
    </div>
);

export default MapLoader;
