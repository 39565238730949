// LocationSettingsMenu.js
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import styles from '../UserSettingsMenu/UserSettingsMenu.module.css';
import { IoTicketOutline } from "react-icons/io5";
import { IoBagAddOutline } from "react-icons/io5";
import { IoBatteryChargingOutline } from "react-icons/io5";
import { IoBatteryDeadOutline } from "react-icons/io5";
import { IoStorefrontSharp } from "react-icons/io5";
import { CgListTree } from "react-icons/cg";
import { TbSettings } from "react-icons/tb";
import { MdManageAccounts } from "react-icons/md";
import { SiChartdotjs } from "react-icons/si";
import { SiContactlesspayment } from "react-icons/si";
import { PiContactlessPayment } from "react-icons/pi";
import {CiLogout} from "react-icons/ci";
import { SiHomeassistantcommunitystore } from "react-icons/si";

import SuperAdminSettingsMenu from "../SuperAdminSettings/SuperAdminSettingsMenu/SuperAdminSettingsMenu";
import { FaMoon, FaSun } from 'react-icons/fa';  // Import icons for theme switcher
import {useTheme} from "../ThemeContext/ThemeContext";
import ThemeToggle from "../ThemeToggle/ThemeToggle";





const LocationAdminMenu = ({ handleShowHeader, handleShowSettingsHeader, handleLogout, user }) => {
    const { isDarkMode, toggleTheme } = useTheme();

    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true);
    }, [handleShowHeader, handleShowSettingsHeader]);

    const handleThemeToggle = () => {
        toggleTheme();
        localStorage.setItem('theme', isDarkMode ? 'light' : 'dark');
    };


    return (
        <>
            <nav className={styles.userSettingsMenu}>
                <h2 className={styles.title}>Settings</h2>
                <ul className={styles.menuList}>
                    <div className={styles.section}>
                        <h2 className={styles.sectionHeader}>Manage your deals</h2>
                        <li className={styles.menuItem}>
                            <Link to="/location-settings/add-deal" className={styles.menuLink}>
                                <div className={styles.iconAndName}>
                                    <IoTicketOutline/>
                                    Add Deal
                                </div>
                                <span className={styles.arrow}>›</span>
                            </Link>
                        </li>
                    </div>

                    <div className={styles.section}>
                        <h2 className={styles.sectionHeader}>Set your location settings</h2>

                        <li className={styles.menuItem}>
                            <Link to="/location-settings/location-manager" className={styles.menuLink}>
                                <div className={styles.iconAndName}>
                                    <SiHomeassistantcommunitystore />
                                    Dispensary Manager
                                </div>
                                <span className={styles.arrow}>›</span>
                            </Link>
                        </li>
                    </div>
                    <div className={styles.section}>
                        <h2 className={styles.sectionHeader}>Update your payments</h2>

                        <li className={styles.menuItem}>
                            <Link to="/location-settings/subscription" className={styles.menuLink}>
                                <div className={styles.iconAndName}>
                                    <PiContactlessPayment/>
                                    Subscription Settings
                                    <span className={styles.arrow}>›</span>
                                </div>
                            </Link>
                        </li>
                    </div>
                    <div className={styles.toggleContainer}>
                        <ThemeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme}/>
                    </div>

                    {user.role === 'superadmin' && (<SuperAdminSettingsMenu user={user} handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}/>)}

                    <div className={styles.section}>
                        <div className={styles.logoutWrap}>
                            <Link className={`${styles.menuLink} ${styles.logout}`}>
                                <div className={`${styles.iconAndName} ${styles.logoutText}`} onClick={handleLogout}>
                                    <CiLogout className={`${styles.menuIcon} ${styles.logoutIcon}`} />
                                    Logout
                                </div>
                            </Link>
                        </div>
                    </div>
                </ul>
            </nav>
        </>
    )
};

export default LocationAdminMenu;

