import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../UserSettingsMenu/UserSettingsMenu.module.css';
import { FaUsers, FaStore, FaUserPlus, FaUserCog, FaChartBar, FaCog } from "react-icons/fa";
import { HiChevronRight } from "react-icons/hi2";
import {SiHomeassistantcommunitystore} from "react-icons/si";
import { PiUserCirclePlus } from "react-icons/pi";
import { FcComboChart } from "react-icons/fc";


const menuItems = {
    manageUsersAndDispensaries: [
        { link: "/superadmin/manage-users", icon: FaUsers, text: "Manage Users", superadminOnly: true },
        { link: "/superadmin/manage-dispensaries", icon: SiHomeassistantcommunitystore, text: "Manage Dispensaries", superadminOnly: true },
        { link: "/superadmin/manage-dispensary-admins", icon: PiUserCirclePlus, text: "Assign User to Dispensary", superadminOnly: false },
    ],
    systemManagement: [
        { link: "/superadmin/manage-roles", icon: FaUserCog, text: "Manage Roles" },
        { link: "/superadmin/analytics", icon: FcComboChart, text: "System Analytics", customClass: 'pluugBranding' },
        { link: "/superadmin/system-settings", icon: FaCog, text: "System Settings" },
    ],
};

const SuperAdminSettingsMenu = ({ handleShowHeader, handleShowSettingsHeader, user }) => {
    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(true);
    }, [handleShowHeader, handleShowSettingsHeader]);

    const renderMenuItem = ({ link, icon: Icon, text, customClass }) => (
        <li key={text} className={`${styles.menuItem} ${styles.superAdminMenuItem}`}>
            <Link to={link} className={`${styles.menuLink} ${styles.superAdminMenuLink}`}>
                <div className={styles.iconAndName}>
                    <Icon className={styles[customClass]} />
                    {text}
                </div>
                <span className={styles.arrow}><HiChevronRight /></span>
            </Link>
        </li>
    );

    return (
        <nav className={`${styles.superAdminSettingsMenu}`}>
            <div className={styles.superAdminTextHeader}>
                <h2 className={styles.superAdminTitle}>Super Admin Settings</h2>
            </div>
            <ul className={`${styles.menuList} ${styles.superAdminMenuList}`} style={{marginTop: "9px"}}>
                <div className={`${styles.section} ${styles.superAdminSection}`}>
                    <h2 className={`${styles.sectionHeader} ${styles.superAdminSectionHeader}`}>Manage Users and Dispensaries</h2>
                    {menuItems.manageUsersAndDispensaries.map(item =>
                                                                  (user.role === 'superadmin' || !item.superadminOnly) && renderMenuItem(item)
                    )}
                </div>

                {user.role === 'superadmin' && (
                    <div className={`${styles.section} ${styles.superAdminSection}`}>
                        <h2 className={`${styles.sectionHeader} ${styles.superAdminSectionHeader}`}>System Management</h2>
                        {menuItems.systemManagement.map(renderMenuItem)}
                    </div>
                )}
            </ul>
        </nav>
    );
};

export default SuperAdminSettingsMenu;
