import React from 'react';
import styles from './Loader.module.css';


const ProductLoaderCard = ({ customClassName, count = 1, priceBelow = false }) => {
    const loaderCards = [];

    for (let i = 0; i < count; i++) {
        loaderCards.push(
            <div key={i} className={customClassName || styles.loaderCard}>
                <div className={styles.cardWrapper}>
                    {priceBelow && (<div className={`${styles.imageFHPlaceholder} ${styles.animatePulse}`}></div>)}
                    {!priceBelow && (<div className={`${styles.imagePlaceholder} ${styles.animatePulse}`}></div>)}

                    {!priceBelow && (<>
                    <div className={styles.cardContent}>
                            <div className={`${styles.textPlaceholder} ${styles.animatePulse}`}></div>
                            <div className={`${styles.textPlaceholder} ${styles.animatePulse} ${styles.shortPlaceholder}`}></div>
                        </div>
                    </>)}

                </div>

                {priceBelow && (<>
                    <div className={`${styles.namePlaceholder} ${styles.animatePulse}`}></div>
                    <div className={`${styles.pricePlaceholder} ${styles.animatePulse} ${styles.shortPlaceholder}`}></div>
                </>)}
            </div>
        );
    }

    return loaderCards;
};

export default ProductLoaderCard;
