import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import useApi from '../../utils/useApi';
import styles from './Search.module.css';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import AnimatedSearchIndicator from "../Icons/AnimatedSearchIndicator/AnimatedSearchIndicator";
import BusinessList from "../BusinessList/BusinessList";
import ProductList from "../ProductList/ProductList";
import { useVoiceSearch } from '../VoiceGPT/VoiceSearchContext';

const SearchApi = ({ handleShowHeader, handleShowSettingsHeader, geoposition }) => {
    const [initial, setInitial] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState('All');
    const [searchResults, setSearchResults] = useState({});
    const { searchApi, isLoading, error } = useApi();
    const [isSearching, setIsSearching] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { transcript, shouldSearch, resetSearch } = useVoiceSearch();
    const [prevSearchTerms, setPrevSearchTerms] = useState([]);

    const performSearch = useCallback(async (term, tab) => {
        if (term.length > 2) {
            setIsSearching(true);
            try {
                let results;
                if (tab === 'All') {
                    results = await searchApi(geoposition, term);
                } else {
                    results = await searchApi(geoposition, term, tab.toLowerCase());
                }
                setSearchResults(results);

                // Save search term to local storage
                const updatedTerms = [term, ...prevSearchTerms.filter(t => t !== term)].slice(0, 6);
                localStorage.setItem('prevSearchTerms', JSON.stringify(updatedTerms));
                setPrevSearchTerms(updatedTerms);
            }
            catch (err) {
                if (err.name !== 'AbortError') {
                    console.error('Search failed:', err);
                }
            }
            finally {
                setIsSearching(false);
            }
        } else {
            setSearchResults({});
        }
    }, [searchApi, geoposition, prevSearchTerms]);

    // Initial URL query check and local storage load
    useEffect(() => {
        setInitial(false);
        const searchParams = new URLSearchParams(location.search);
        const query = searchParams.get('q');

        if (query) {
            setSearchTerm(query);
            setDebouncedSearchTerm(query);
            performSearch(query, activeTab);
        } else {
            // Load last search term from local storage if no query in URL
            const storedTerms = JSON.parse(localStorage.getItem('prevSearchTerms') || '[]');
            if (storedTerms.length > 0) {
                setSearchTerm(storedTerms[0]);
                setDebouncedSearchTerm(storedTerms[0]);
                performSearch(storedTerms[0], activeTab);
            }
            setPrevSearchTerms(storedTerms.slice(0,6));
        }
    }, []);

    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(false);
    }, [handleShowHeader, handleShowSettingsHeader]);

    useEffect(() => {
        if (shouldSearch && transcript && !initial) {
            setDebouncedSearchTerm(transcript);
            performSearch(transcript, activeTab);
            resetSearch();
        }
    }, [shouldSearch, activeTab]);

    // Debounce effect
    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300); // 300ms delay

        return () => clearTimeout(timer);
    }, [searchTerm]);

    // Perform search when debounced search term changes
    useEffect(() => {
        performSearch(debouncedSearchTerm, activeTab);
    }, [debouncedSearchTerm, activeTab]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(`/search-all?q=${encodeURIComponent(searchTerm)}`);
    };

    const handleHistoryItemClick = (term) => {
        setSearchTerm(term);
        setDebouncedSearchTerm(term);
        performSearch(term, activeTab);
    };

    const tabs = ['All', 'Deals', 'Products', 'Locations'];

    const renderSearchHistory = () => {
        if (prevSearchTerms.length === 0) return null;

        return (
            <div className={styles.categorySection}>
                <h2 className={styles.categoryTitle}>Recent Searches</h2>
                <ul className={styles.resultsList}>
                    {prevSearchTerms.map((term, index) => (
                        <li key={index} className={styles.resultItem}>
                            <AnimatedSearchIndicator customClass={styles.searchIndicator} />
                            <button onClick={() => handleHistoryItemClick(term)} className={styles.historyItem}>
                                {term}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const renderSearchResults = () => {
        if (activeTab === 'All') {
            return (
                <>
                    {renderSearchHistory()}
                    {searchResults.deals?.data?.length > 0 && (
                        <div className={styles.categorySection}>
                            <h2 className={styles.categoryTitle}>Deals</h2>
                            <ProductList products={searchResults.deals.data.slice(0, 3)} />
                        </div>
                    )}
                    {searchResults.products?.data?.length > 0 && (
                        <div className={styles.categorySection}>
                            <h2 className={styles.categoryTitle}>Products</h2>
                            <ProductList products={searchResults.products.data.slice(0, 3)}
                                isLoading={isSearching}
                            />
                        </div>
                    )}
                    {searchResults.locations?.data?.length > 0  && (
                        <div className={styles.categorySection}>
                            <h2 className={styles.categoryTitle}>Locations</h2>
                            <BusinessList businesses={searchResults.locations.data.slice(0, 3)} />
                        </div>
                    )}
                </>
            );
        } else if (activeTab === 'Products') {
            return searchResults.products?.exists ? (
                <ProductList products={searchResults.products.data} isLoading={isSearching} />
            ) : (
                       <p className={styles.message}>No products found</p>
                   );
        } else if (activeTab === 'Locations') {
            return searchResults.locations?.exists ? (
                <BusinessList businesses={searchResults.locations.data} />
            ) : (
                       <p className={styles.message}>No locations found</p>
                   );
        } else if (activeTab === 'Deals') {
            return searchResults.deals?.data?.length ? (
                <ProductList products={searchResults.deals.data} />
            ) : (
                       <p className={styles.message}>No deals found</p>
                   );
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.searchInputWrapper}>
                <div className={styles.backArrow}>
                    <button onClick={() => navigate(-1)} className={styles.backButton}>
                        <IoArrowBackCircleOutline />
                    </button>
                </div>
                <form onSubmit={handleSubmit} className={styles.searchInputContainer}>
                    <AnimatedSearchIndicator customClass={styles.searchIndicator} />
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder="Search..."
                        className={styles.searchInput}
                    />
                    <button type="submit" className={styles.searchButton}>
                        {isSearching ? <AnimatedSearchIndicator /> : <Search size={24} color="white" />}
                    </button>
                </form>
            </div>

            <div className={styles.tabsContainer}>
                {tabs.map((tab) => (
                    <button
                        key={tab}
                        onClick={() => handleTabClick(tab)}
                        className={`${styles.tab} ${activeTab === tab ? styles.activeTab : ''}`}
                    >
                        {tab}
                    </button>
                ))}
            </div>

            {isLoading ? (
                <p className={styles.message}>Loading...</p>
            ) : error ? (
                <p className={styles.message}>Error: {error}</p>
            ) : (
                    <div className={styles.resultsContainer}>{renderSearchResults()}</div>
                )}
        </div>
    );
};

export default SearchApi;
