import React, { useEffect, useCallback } from 'react';
import introJs from 'intro.js';
import 'intro.js/minified/introjs.min.css';

const IntroTour = ({ steps, onComplete, delay = 1000 }) => {
    const findElement = (selector, context = document) => {
        if (selector.startsWith('.tour-product-item ')) {
            const productItem = context.querySelector('.tour-product-item');
            if (productItem) {
                console.log(productItem.querySelector(selector.replace('.tour-product-item ', '')));
                console.log(context.querySelector(selector));
                return productItem.querySelector(selector.replace('.tour-product-item ', ''));
            }
        }
        return context.querySelector(selector);
    };

    const scrollToElement = (element) => {
        if (element) {
            const rect = element.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const targetTop = rect.top + scrollTop - 100;

            window.scrollTo({
                                top: targetTop,
                                behavior: 'smooth'
                            });

            return new Promise(resolve => setTimeout(resolve, 500));
        }
        return Promise.resolve();
    };

    const waitForElement = (selector, timeout = 5000) => {
        return new Promise((resolve, reject) => {
            const startTime = Date.now();
            const checkElement = () => {
                const element = findElement(selector);
                if (element) {
                    resolve(element);
                } else if (Date.now() - startTime > timeout) {
                    reject(new Error(`Element ${selector} not found within ${timeout}ms`));
                } else {
                    setTimeout(checkElement, 100);
                }
            };
            checkElement();
        });
    };

    const startTour = useCallback(async () => {
        const tour = introJs();

        const modifiedSteps = await Promise.all(steps.map(async (step) => {
            try {
                const element = await waitForElement(step.element);
                return {
                    ...step,
                    element,
                    highlightClass: step.customHighlight ? `customHighlight ${step.customHighlight}` : 'customHighlight'
                };
            } catch (error) {
                console.warn(`Step element not found: ${step.element}`);
                return null;
            }
        }));

        const validSteps = modifiedSteps.filter(step => step !== null);

        tour.setOptions({
                            steps: validSteps,
                            exitOnOverlayClick: false,
                            exitOnEsc: true,
                            showStepNumbers: true,
                            showBullets: false,
                            showProgress: true,
                            disableInteraction: false,
                            tooltipClass: 'customTooltip',
                            highlightClass: 'customHighlight',
                            doneLabel: "Finish",
                            nextLabel: "Next",
                            prevLabel: "Previous",
                            scrollToElement: true,
                            scrollTo: 'tooltip'
                        });



        tour.oncomplete(() => {
            if (onComplete) onComplete();
        });

        tour.onexit(() => {
            if (onComplete) onComplete();
        });

        if (validSteps.length > 0) {
            tour.start();
        } else {
            console.warn('No valid tour steps found. Tour not started.');
            if (onComplete) onComplete();
        }
    }, [steps, onComplete]);

    useEffect(() => {
        const tourTimeout = setTimeout(() => {
            startTour();
        }, delay);

        return () => {
            clearTimeout(tourTimeout);
        };
    }, [startTour, delay]);

    return null;
};

export default IntroTour;
