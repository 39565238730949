import React, { useState, useEffect, useRef } from 'react';

const Chat = ({ handleShowHeader, handleShowSettingsHeader, user }) => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const ws = useRef(null);
    const [dispensaryId, setDispensaryId] = useState(366515);

    useEffect(() => {
        // Connect to WebSocket server
        ws.current = new WebSocket('ws://localhost:3001');

        ws.current.onopen = () => {
            console.log('WebSocket connection established');
        };

        ws.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'new_message') {
                setMessages((prevMessages) => [...prevMessages, data.message]);
            }
        };

        return () => {
            ws.current.close();
        };
    }, []);

    const sendMessage = () => {
        if (inputMessage.trim() !== '') {
            const message = {
                type: 'chat_message',
                chatId: 1, // You should replace this with the actual chat ID
                senderId: user.id,
                content: inputMessage,
            };
            ws.current.send(JSON.stringify(message));
            setInputMessage('');
        }
    };

    return (
        <div className="chat-container">
            <div className="message-list">
                {messages.map((message) => (
                    <div key={message.id} className={`message ${message.sender_id === userId ? 'sent' : 'received'}`}>
                        {message.content}
                    </div>
                ))}
            </div>
            <div className="message-input">
                <input
                    type="text"
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                />
                <button onClick={sendMessage}>Send</button>
            </div>
        </div>
    );
};

export default Chat;
