import React, {useState, useEffect, useCallback, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import styles from "../App/App.module.css";
import SearchBar from "../SearchBar/SearchBar";
import MapComponent from "../MapComponent/MapComponent";
import MapLoader from "../MapLoader/MapLoader";
import NavbarFilter from "../NavbarFilter/NavbarFilter";
import ProductList from "../ProductList/ProductList";
import ProductLoader from "../ProductList/ProductLoader/ProductLoader";
import useApi from "../../utils/useApi";

const Results = ({ geoposition, handleShowHeader, handleShowSettingsHeader, changeGeoPosition, showTour, setShowTour }) => {
    const [products, setProducts] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const [noDeals, setNoDeals] = useState(false);
    const [filterCounts, setFilterCounts] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [category, setCategory] = useState(2);
    const [mapMarker, setMapMarker] = useState(null);
    const [sortBy, setSortBy] = useState('near_you');
    const [distance, setDistance] = useState(3);
    const [priceFrom, setPriceFrom] = useState(null);
    const [priceTo, setPriceTo] = useState(null);
    const [weight, setWeight] = useState(null);
    const [cbdFrom, setCbdFrom] = useState(null);
    const [cbdTo, setCbdTo] = useState(null);
    const [thcFrom, setThcFrom] = useState(null);
    const [thcTo, setThcTo] = useState(null);
    const [strainType, setStrainType] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [noResults, setNoResults] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const MemoizedMapComponent = React.memo(MapComponent);
    const searchBarRef = useRef(null);
    const { searchGeoProducts, searchGeoBusinesses, getFilterCounts } = useApi();
    const navbarFilterRef = useRef(null);
    const [isNavbarFilterPinned, setIsNavbarFilterPinned] = useState(false);
    const [navbarFilterOriginalTop, setNavbarFilterOriginalTop] = useState(0);



    useEffect(() => {
        const setInitialPosition = () => {
            if (navbarFilterRef.current) {
                const rect = navbarFilterRef.current.getBoundingClientRect();
                setNavbarFilterOriginalTop(rect.top + window.pageYOffset);
            }
        };

        setInitialPosition();
        window.addEventListener('load', setInitialPosition);
        window.addEventListener('resize', setInitialPosition);

        return () => {
            window.removeEventListener('load', setInitialPosition);
            window.removeEventListener('resize', setInitialPosition);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (navbarFilterRef.current) {
                const scrollY = window.pageYOffset;
                const unpinOffset = 50; // Adjust this value to change when unpinning occurs

                if (scrollY > navbarFilterOriginalTop) {
                    setIsNavbarFilterPinned(true);
                } else if (scrollY < navbarFilterOriginalTop - unpinOffset) {
                    setIsNavbarFilterPinned(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [navbarFilterOriginalTop]);

    useEffect(() => {
        handleShowHeader(true);
        handleShowSettingsHeader(false);
    }, [handleShowHeader, handleShowSettingsHeader]);

    const handleSearchResults = useCallback((results) => {
        setIsSearching(false);
        if (results.data.length <= 0 && !results.pagination) {
            setHasMore(false);
            return;
        }

        if(results.noResults){
            setNoDeals(results.noResults);
        } else {
            setNoDeals(results.noResults);
        }

        setProducts(prevProducts => (page === 1 ? results.data : [...prevProducts, ...results.data]));
        setHasMore(results.pagination.current_page < results.pagination.last_page);
        setPage(prevPage => prevPage + 1);

        if (results.pagination.total === 0) {
            setNoResults(true);
        } else {
            setNoResults(false);
        }
    }, [page]);

    const handleSetBusinesses = (results) => {
        if (results.data.length <= 0) {
            setHasMore(false);
            return;
        }

        setBusinesses(results.data);
    };

    const resetSearch = useCallback(() => {
        setProducts([]);
        setBusinesses([])
        setPage(1);
        setHasMore(true);
        setNoResults(null);
    }, []);

    const performSearch = useCallback((currentPage, currentHasMore) => {
        let searchPage = currentPage ?? page;
        let searchHasMore = currentHasMore ?? hasMore;
        console.log('Outside Perform Search');
        console.log(hasMore);
        console.log(isSearching);

        if (geoposition && searchHasMore && !isSearching) {
            console.log('In Perform Search');

            setIsSearching(true);
            return searchGeoProducts(searchTerm, geoposition, category, sortBy, searchPage, distance, priceFrom, priceTo, weight, cbdFrom, cbdTo, thcFrom, thcTo, strainType)
                .then(handleSearchResults)
                .catch(error => {
                    console.error("Search error:", error);
                    setIsSearching(false);
                });
        }
        return Promise.resolve();
    }, [geoposition, searchTerm, category, sortBy, page, hasMore, isSearching, searchGeoProducts, handleSearchResults, priceFrom, priceTo, weight, cbdFrom, cbdTo, thcFrom, thcTo, distance, strainType]);

    const getBusinesses = useCallback(() => {

        return searchGeoBusinesses(searchTerm, geoposition, category, sortBy, page, distance, priceFrom, priceTo, weight, cbdFrom, cbdTo, thcFrom, thcTo, strainType)
            .then(handleSetBusinesses)
            .catch(error => {
                console.error("Search error:", error);
            });

    }, [geoposition,searchTerm, category, sortBy, searchGeoProducts, handleSearchResults, priceFrom, priceTo, weight, cbdFrom, cbdTo, thcFrom, thcTo, distance, strainType]);

    useEffect(() => {
        console.log('Geoposition Changed');
        if (geoposition) {
            console.log('Geoposition Changed 2');

            resetSearch();
            performSearch();
            getBusinesses();
        }
    }, [geoposition, category, searchTerm, sortBy, priceFrom, priceTo, weight, cbdFrom, cbdTo, thcFrom, thcTo, strainType, distance]);

    const fetchFilterCounts = useCallback(() => {
        if (geoposition) {
            getFilterCounts(geoposition, searchTerm, category, distance, strainType, weight, priceFrom, priceTo)
                .then(counts => {
                    setFilterCounts(counts);
                })
                .catch(error => {
                    console.error("Error fetching filter counts:", error);
                });
        }
    }, [geoposition, searchTerm, category, strainType, weight, priceFrom, priceTo, distance, getFilterCounts]);

    useEffect(() => {
        fetchFilterCounts();
    }, [fetchFilterCounts]);

    const handleStrainTypeChange = (newStrainType) => {
        setStrainType(newStrainType);
        resetSearch();
    };

    const handleCBDChange = (from, to) => {
        setCbdFrom(from);
        setCbdTo(to);
        resetSearch();
    };

    const handleTHCChange = (from, to) => {
        setThcFrom(from);
        setThcTo(to);
        resetSearch();
    };

    const handleDistanceChange = (newDistance) => {
        setDistance(newDistance);
        resetSearch();
    }

    const handleSearchTermChange = useCallback((term) => {
        setSearchTerm(term);
        resetSearch();
    }, []);

    const handleCategoryChange = (newCategory) => {
        console.log("CHANGING CATEGORY");
        console.log(newCategory);
        setCategory(newCategory.id);
        setMapMarker(newCategory.mapMarker);
        resetSearch();

        if (category == newCategory.id) {
            console.log("Performing Search");
            getBusinesses();
            performSearch(1, true);
        }
    };

    const handleSortByChange = (newSortBy) => {
        setSortBy(newSortBy);
        resetSearch();
    };

    const handlePriceChange = (from, to) => {
        setPriceFrom(from);
        setPriceTo(to);
        resetSearch();
    };

    const handleWeightChange = (newWeight) => {
        setWeight(newWeight);
        resetSearch();
    };

    const bottomPadding = { paddingBottom: "400px" };

    return (
        <div className={styles.App}>
            <SearchBar
                className="search-bar"
                ref={searchBarRef}
                geoposition={geoposition}
                onLocationChange={changeGeoPosition}
                onSearchResults={handleSearchResults}
                onSearchTermChange={handleSearchTermChange}
                onSortByChange={handleSortByChange}
            >
                {((businesses.length === 0 || !businesses)) ? (
                    <MapLoader/>
                ) : (
                     <MemoizedMapComponent mapMarker={mapMarker} businesses={businesses}/>
                 )}
            </SearchBar>
            <div
                ref={navbarFilterRef}
                className={`${styles.navbarFilterContainer} ${isNavbarFilterPinned ? styles.pinned : ''}`}
                style={isNavbarFilterPinned ? { position: 'fixed', top: 0, left: 0, right: 0 } : null}
            >
                <NavbarFilter
                    onTermChange={handleSearchTermChange}
                    onCategoryChange={handleCategoryChange}
                    onDistanceChange={handleDistanceChange}
                    onPriceChange={handlePriceChange}
                    onWeightChange={handleWeightChange}
                    onCBDChange={handleCBDChange}
                    onTHCChange={handleTHCChange}
                    onStrainTypeChange={handleStrainTypeChange}
                    filterCounts={filterCounts}
                    pinned={isNavbarFilterPinned}
                    showTour={showTour}
                    setShowTour={setShowTour}
                />
            </div>
            {isNavbarFilterPinned && <div style={{ height: '54px' }}/>} {/* Placeholder to prevent content jump */}
            {(noResults && !isSearching) && (<div className={styles.noResults}>No results found</div>)}

            <div className={`${styles.productListWrap} product-list`}>
                <ProductList
                    page={page}
                    products={products}
                    customStyle={bottomPadding}
                    loadMore={performSearch}
                    hasMore={hasMore}
                    isLoading={isSearching}
                    noDeals={noDeals}
                    showTour={showTour}
                    setShowTour={setShowTour}
                />
            </div>
        </div>
    );
}

export default Results;
