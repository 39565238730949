import React, {useEffect, useState, useCallback} from "react";
import ReactDOM from "react-dom";
import {Link} from "react-router-dom";
import styles from "../DealList/DealList.module.css";
import {X, MapPin, Phone, Clock, Star} from "lucide-react";
import useApi from "../../utils/useApi";
import StarRating from "../StarRating/StarRating";

import {parseISO, format} from 'date-fns';
import ProductActions from "../ProductActions/ProductActions";

const DealBottomSheet = ({ businessId, isOpen, onClose, deal, productOrig, hideStore }) => {
    const [isClosing, setIsClosing] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [product, setProduct] = useState(null);
    const { getProduct } = useApi();

    const fetchProduct = useCallback(async (productId) => {
        try {
            console.log("Inside fetchProduct function. ProductId:", productId);
            const productData = await getProduct(productId);
            console.log("Fetched product data:", productData);
            setProduct(productData);
        }
        catch (error) {
            console.error("Error fetching product:", error);
        }
    }, [getProduct]);

    useEffect(() => {
        if (isOpen && deal) {
            document.body.style.overflowY = "hidden";
            console.log("Product ID from deal:", productOrig.id);
            if (productOrig.id) {
                console.log("Attempting to fetch product for ID:", productOrig.id);
                fetchProduct(productOrig.id);
            } else {
                console.warn("No productId found in the deal object");
            }
        } else if (!isOpen) {
            setIsClosing(false);
            setProduct(null); // Reset product when closing
        }
    }, [isOpen, productOrig, fetchProduct, deal]);

    const handleClose = () => {
        setIsClosing(true);
        document.body.style.overflowY = "auto";

        setTimeout(() => {
            setShowDescription(false);
            onClose();
        }, 300);
    };

    const viewMore = (e) => {
        e.stopPropagation();
        setShowDescription(true);
    }

    const closeViewMore = (e) => {
        e.stopPropagation();
        setShowDescription(false);
    }

    if (!isOpen && !isClosing) return null;

    const formatSchedule = (schedule) => {
        if (!schedule) return "Not available";
        const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        const today = daysOfWeek[new Date().getDay()];
        const todaySchedule = schedule[today];
        if (!todaySchedule || !todaySchedule.isOpen) return "Closed today";

        const formatTime = (timeString) => {
            // Extract hours and minutes directly from the string
            const [, time] = timeString.split('T');
            const [hours, minutes] = time.split(':');

            // Convert to 12-hour format
            let hour = parseInt(hours, 10);
            const ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12 || 12; // Convert 0 to 12 for midnight

            return `${hour}:${minutes} ${ampm}`;
        };

        const openTime = formatTime(todaySchedule.open);
        const closeTime = formatTime(todaySchedule.close);

        return `${openTime} - ${closeTime}`;
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return ReactDOM.createPortal(
        <div
            className={`${styles.bottomSheetOverlay} ${isClosing ? styles.closing : ''}`}
            onClick={handleClose}
        >
            <div
                className={`${styles.bottomSheetContent} ${isOpen ? styles.open : ''} ${isClosing ? styles.closing : ''}`}
                onClick={(e) => e.stopPropagation()}
            >
                <button className={styles.bottomSheetCloseButton} onClick={handleClose}>
                    <X size={24}/>
                </button>


                {product ? (
                    <div className={styles.bottomSheetProductInfo}>
                        <div className={styles.bottomSheetProductWrap}>
                            <div className={styles.bottomSheetProductImageWrapper}>
                                <img src={product.formatted_thumbnail_url} alt={product.name} className={styles.bottomSheetProductImage}/>
                                {(deal && product) && (
                                    <>
                                        {(deal.imageUrl && !product.jane_id) && (<img src={deal.imageUrl} alt={deal.title} className={styles.bottomSheetDealImage}/>)}
                                    </>
                                )}


                            </div>
                            <div className={styles.bottomSheetProductInfo}>
                                <div className={styles.bottomSheetProductDetails}>
                                    <div className={styles.productNameDetails}>
                                        <h3>{product.name}</h3>
                                        {product.brand.name && (
                                            <span className={`${styles.bottomSheetBrandName} tour-brand-name`}>{product.brand.name}</span>
                                        )}
                                    </div>

                                    <p className={styles.bottomSheetProductPrice}>
                                        {product.sort_price ? (
                                            <>
                                                <span className={styles.bottomSheetOriginalPrice}>${parseFloat(product.price).toFixed(2)}</span>
                                                <span className={styles.bottomSheetSalePrice}>${parseFloat(product.sort_price).toFixed(2)}</span>
                                            </>
                                        ) : (
                                             `$${parseFloat(product.price).toFixed(2)}`
                                         )}
                                    </p>
                                </div>

                                <div className={styles.bottomSheetMetaVoteRow}>
                                    <div>
                                        <div className={`${styles.productMetadata} tour-product-metadata`}>
                                            {product?.normalized_quantity && (
                                                <span className={`${styles.productLabels} ${styles[product?.strain_type?.name?.toLowerCase()]} tour-product-quantity`}>
                                {product?.normalized_quantity}
                            </span>
                                            )}
                                            {product?.strain_type?.name && (
                                                <span className={`${styles.productLabels} ${styles.strainTypeLabel} ${styles[product.strain_type.name.toLowerCase()]} tour-strain-type`}>
                                {capitalizeFirstLetter(product.strain_type.name)}
                            </span>
                                            )}
                                            {product.product_category && (
                                                <span className={`${styles.productLabels} ${styles[product?.strain_type?.name?.toLowerCase()]} tour-product-category`}>
                                {product.product_category}
                            </span>
                                            )}
                                            {product.dispensary && (
                                                <span className={`${styles.dispensaryName} tour-dispensary-name`}>{product.dispensary.name}</span>
                                            )}
                                            {product.thc_content_label && product.thc_content_label !== "-" && (
                                                <span className={`${styles.thcContent} tour-thc-content`}>{product.thc_content_label} THC</span>
                                            )}
                                            {product.cbd_content_label && product.cbd_content_label !== "-" && (
                                                <span className={`${styles.cbdContent} tour-cbd-content`}>{product.cbd_content_label} CBD</span>
                                            )}
                                            {product.distance_mi !== undefined && (
                                                <span className={`${styles.dispensaryName} tour-distance`}>
                                {product.distance_mi.toFixed(1)} mi
                            </span>
                                            )}
                                        </div>
                                        {(deal && product) && (
                                            <>
                                                {!product.jane_id && (
                                                    <div className={styles.bottomSheetDealInfo}>
                                                        <p>{deal.title}</p>
                                                        {deal.endsAt && (<p>Valid until: {new Date(deal.endsAt).toLocaleDateString()}</p>)}
                                                        {deal.finePrint && <p className={styles.bottomSheetFinePrint}>{deal.finePrint}</p>}
                                                    </div>
                                                )}

                                            </>
                                        )}

                                    </div>
                                    <ProductActions product={product}/>

                                </div>
                                {showDescription === false && (
                                    <p>
                                        {(product.description && product.description.length > 100 && showDescription === false)
                                         ? `${product.description.slice(0, 100)}... `
                                         : product.description}

                                        {(showDescription === false && product?.description?.length > 100) && (<span className={styles.moreLink} onClick={viewMore}>More</span>)}
                                    </p>
                                )}
                                {showDescription && (<p onClick={closeViewMore}>{product.description}</p>)}


                            </div>


                        </div>

                        <h2 className={styles.attentionLabel}>Attention:</h2>
                        <p>
                            Some deals may appear active, but may be expired or out of stock. For any issues, please call this store.
                        </p>



                        <div className={styles.bottomSheetButtons}>
                            {(!hideStore && !businessId) && (
                                <Link to={`/business/${product.dispensary_id}`} className={styles.bottomSheetViewStore}>
                                    View Store
                                </Link>
                            )}

                            <a target="_blank" rel="noopener noreferrer" href={product.link} className={styles.bottomSheetGetDeal}>Get Deal</a>

                        </div>

                    </div>
                ) : (
                     <p>Loading product information...</p>
                 )}


                {product && product.dispensary && (
                    <Link to={`/business/${product.dispensary_id}`}>
                        <div className={styles.bottomSheetDispensaryInfo}>
                            <div className={styles.dispensaryHeader}>
                                <img src={product.dispensary.logoImage} alt={product.dispensary.name} className={styles.dispensaryLogo}/>
                                <div>
                                    <h3>{product.dispensary.name}</h3>
                                    <div className={styles.ratingWrapper}>
                                        <StarRating rating={product.dispensary.starRating} maxRating={5} size={15}/>

                                        <span>{product.dispensary.starRating.toFixed(1)}</span>
                                        <span>({product.dispensary.numberOfReviews} reviews)</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.dispensaryDetails}>
                                <p><MapPin size={16}/> {product.dispensary.address1}, {product.dispensary.city}, {product.dispensary.state} {product.dispensary.zip}</p>
                                <p><Phone size={16}/> {product.dispensary.phone}</p>
                                <p><Clock size={16}/> {formatSchedule(product.dispensary.schedule, product.dispensary.timeZone)}</p>
                            </div>
                        </div>
                    </Link>
                )}
            </div>
        </div>,
        document.body
    );
};

export default DealBottomSheet;
