import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import styles from './Login.module.css';
import { Eye, EyeOff, User, Lock } from 'lucide-react';
import { CgLock } from "react-icons/cg";
import { IoPersonCircleOutline } from "react-icons/io5";

import bootstrapStyles from './LoginBootstrap.module.css';
import logo from '../../assets/logo2.svg';
import logoLight from '../../assets/logo.svg';
import {useTheme} from "../ThemeContext/ThemeContext";
import {useNavVisibility} from '../NavVisibilityContext/NavVisibilityContext';


const Login = ({ onLogin, handleShowHeader, handleShowSettingsHeader }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { isDarkMode } = useTheme();  // Use the useTheme hook
    const logoSrc = isDarkMode ? logo : logo;  // Choose logo based on theme
    const { setIsNavVisible } = useNavVisibility();


    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(false);
        setIsNavVisible(false);
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        onLogin(email, password);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={styles.container}>
            <div className={styles.absBackground}></div>
            <div className={styles.logoContainer}>
                <img className={styles.logo} src={logoSrc} alt="logo" />
            </div>
            <div className={styles.formContainer}>
                <div className={styles.formCard}>
                    <h2 className={styles.title}>Login</h2>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <div className={styles.inputWrap}>
                            <div className={styles.inputGroup}>

                                <div className={styles.inputWrapper}>
                                    <IoPersonCircleOutline className={styles.icon}/>
                                    <input
                                        className={styles.input}
                                        id="email"
                                        type="text"
                                        value={email}
                                        placeholder="Username / Email Address"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className={styles.inputGroup}>

                                <div className={styles.inputWrapper}>
                                    <CgLock className={styles.icon}/>
                                    <input
                                        className={styles.input}
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className={styles.togglePassword}
                                    >
                                        {showPassword ? (
                                            <EyeOff className={styles.icon}/>
                                        ) : (
                                             <Eye className={styles.icon}/>
                                         )}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={styles.buttonGroup}>
                            <div className={styles.registerLink}>
                                <span>New to pluug? </span>
                                <Link to="/register">Sign up here</Link>
                            </div>
                            <button className={styles.submitButton} type="submit">
                                Login
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    );
};

export default Login;
