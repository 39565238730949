import React, { createContext, useState, useContext } from 'react';

const NavVisibilityContext = createContext();

export const NavVisibilityProvider = ({ children }) => {
    const [isNavVisible, setIsNavVisible] = useState(true);

    return (
        <NavVisibilityContext.Provider value={{ isNavVisible, setIsNavVisible }}>
            {children}
        </NavVisibilityContext.Provider>
    );
};

export const useNavVisibility = () => useContext(NavVisibilityContext);
