import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';


import styles from "./BusinessProfile.module.css";
import useApi from "../../utils/useApi";
import ProfileIconSquare from "../ProfileIconSquare/ProfileIconSquare";
import MapComponent from "../MapComponent/MapComponent";
import StarRating from "../StarRating/StarRating";
import MenuView from "../MenuView/MenuView";
import DealList from "../DealList/DealList";  // Make sure to import DealList

// ICONS
import { BsTelephone } from "react-icons/bs";
import { TbMessageDots } from "react-icons/tb";
import { PiMapPin } from "react-icons/pi";
import { PiGlobe } from "react-icons/pi";
import { TbClockHour4 } from "react-icons/tb";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import { IoMdHeartEmpty } from "react-icons/io";
import {LuPlusSquare} from "react-icons/lu";
import { TfiLocationPin } from "react-icons/tfi";
import CategoryLoader from "../ProductLoaderCard/CategoryLoader";
import NavbarFilter from "../NavbarFilter/NavbarFilter";
import BusinessResults from "./BusinessResults";


const BusinessProfile = ({ handleShowHeader, handleShowSettingsHeader, businessId, showTour, setShowTour }) => {
    const { id } = useParams();
    const [business, setBusiness] = useState(null);
    const [businessMenu, setBusinessMenu] = useState(null);
    const [businessDeals, setBusinessDeals] = useState(null);
    const [currentView, setCurrentView] = useState('deals');
    const [loading, setLoading] = useState({
                                               business: true,
                                               deals: true,
                                               menu: true
                                           });
    const { getBusiness, getBusinessMenu, getBusinessDeals } = useApi();
    const MemoizedMapComponent = React.memo(MapComponent);
    const navigate = useNavigate();

    const isObjectEmpty = (content) => {
        if (!content) return false;
        if (typeof content !== 'object') return false;
        if (Array.isArray(content)) return content.length <= 0;
        console.log(Object.keys(content).length);
        return Object.keys(content).length <= 0;
    };

    useEffect(() => {
        handleShowHeader(false);
        handleShowSettingsHeader(false);
    }, [handleShowHeader, handleShowSettingsHeader]);

    useEffect(() => {
        const fetchBusiness = async () => {
            try {
                const result = await getBusiness(id);
                setBusiness(result);
            } catch (error) {
                console.error('Error fetching business:', error);
            } finally {
                setLoading(prev => ({ ...prev, business: false }));
            }
        };

        const fetchBusinessDeals = async () => {
            try {
                const result = await getBusinessDeals(id);
                setBusinessDeals(result);
            } catch (error) {
                console.error('Error fetching business deals:', error);
            } finally {
                setLoading(prev => ({ ...prev, deals: false }));
            }
        };

        const fetchBusinessMenu = async () => {
            try {
                const result = await getBusinessMenu(id);
                setBusinessMenu(result);
            } catch (error) {
                console.error('Error fetching business menu:', error);
            } finally {
                setLoading(prev => ({ ...prev, menu: false }));
            }
        };

        fetchBusiness();
        fetchBusinessDeals();
        fetchBusinessMenu();
    }, [id, getBusiness, getBusinessDeals, getBusinessMenu]);

    const toggleView = () => {
        setCurrentView(currentView === 'deals' ? 'menu' : 'deals');
    };

    const getFormattedDate = () => {
        const today = new Date();
        const options = { weekday: 'long', month: 'numeric', day: 'numeric', year: '2-digit' };
        const formattedDate = today.toLocaleDateString('en-US', options);
        return formattedDate.replace(',', ', ');
    };

    if (loading.business) return <div className={styles.loading}>Loading business information...</div>;

    const headerIcons = [
        { label: "Call", Icon: BsTelephone, link: `tel:${business.phone_number}` },
        //{ label: "Message", Icon: TbMessageDots, link: `sms:${business.phone_number}` },
        { label: "Directions", Icon: PiMapPin, link: `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(business.name + ' ' + business.address)}`, target: "_blank" },
        { label: "Website", Icon: PiGlobe, link: business?.website },
        { label: "Hours", Icon: TbClockHour4, link: '#hours' }
    ];

    const icons = headerIcons.map(({ label, Icon, link, target }, index) => (
        <a target={target ? target : '_self'} href={link} key={index} className={styles.iconLink}>
            <ProfileIconSquare label={label}>
                <Icon />
            </ProfileIconSquare>
        </a>
    ));

    return (
        <div className={styles.profileContainer}>
            <div className={styles.profileHeader}>
                <div className={styles.businessInfo}>
                    {business.avatar_image.cover ? (
                        <div className={styles.coverImage}>
                            <img src={business.avatar_image.cover} alt={`${business.name} cover`}/>
                        </div>
                    ) : (
                         <MemoizedMapComponent customClass={styles.map} businesses={[business]}>
                             <div className={styles.profileNavHeader}>
                                 <div className={styles.leftSideLinks}>
                                     <Link onClick={() => navigate(-1)}>
                                         <IoArrowBackCircleOutline/>
                                     </Link>
                                 </div>
                                 <div className={styles.rightSideLinks}>
                                     <Link onClick={() => navigate(-1)}>
                                         <IoArrowUpCircleOutline/>
                                     </Link>
                                     <Link to={"/"}>
                                         <IoMdHeartEmpty/>
                                     </Link>
                                 </div>
                             </div>
                         </MemoizedMapComponent>
                     )}
                    <div className={styles.logoImage}>
                        <img src={business.avatar_image.small_url} alt={`${business.name} logo`}/>
                    </div>
                    <h1 className={styles.h1}>{business.name}</h1>
                    <div className={styles.hoursLocationInfo}>
                        <div className={styles.cityInfo}>
                            <TfiLocationPin />
                            {business.city}, {business.state}
                        </div>
                        <div className={business.open_now ? styles.open : styles.closed}>
                            <TbClockHour4 />
                            {business.open_now ? 'Open Now' : 'Closed Now'}
                        </div>
                    </div>
                    <div className={styles.ratingWrap}>
                        <StarRating rating={business.rating} maxRating={5} size={15}/>
                        {business.rating}/5 ({business.reviews_count})
                    </div>
                </div>
                <div className={styles.iconsWrap}>
                    {icons}
                </div>
                <div className={styles.locationDetails}>
                    <a onClick={toggleView} className={styles.viewMenu}>
                        View {currentView === 'menu' ? 'Deals' : 'Menu'}
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href={`https://www.leafly.com/dispensary-info/${business.slug}`}>
                        Store Details
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href={`https://www.leafly.com/dispensary-info/${business.slug}`}>
                        Request Deals
                    </a>
                </div>
            </div>
            <div className={styles.menuHeader}>
                <div className={styles.today}>
                    {getFormattedDate()}
                </div>
                <div className={styles.menuHeaderButtons}>

                    <a className={styles.viewAllButton} onClick={toggleView}>
                        View {currentView === 'deals' ? 'Menu' : 'Deals'}
                    </a>
                </div>
            </div>



            {currentView === 'deals' && (
                <>
                    <BusinessResults businessId={id} handleShowHeader={handleShowHeader} handleShowSettingsHeader={handleShowSettingsHeader}  showTour={showTour} setShowTour={setShowTour} />
                </>

            )}
            {(currentView === 'menu') && (
                loading.menu ? (
                    <div className={styles.loading}>Loading menu...</div>
                ) : businessMenu && !isObjectEmpty(businessMenu) ? (
                    <MenuView categories={businessMenu} businessId={id} />
                ) : (
                        <div className={styles.loading}>No menu available for this business.</div>
                    )
            )}
        </div>
    );
};

export default BusinessProfile;
